@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

html {
    font-size: 62.5%;
}

body {
    font-family: 'Raleway','Noto Sans JP', sans-serif;
    font-weight: 400;
    font-size: 1.6rem;
    font-display: swap;
    color: #333;
    margin: 0;
    padding: 0;
}

/* App.js */
.wrapper {
   width: 100vw;
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center; 
   background: url("./background-image.jpg") center center no-repeat;
   background-size: cover;
}

.container {
    width: 50vw;
    text-align: center;
    padding: 40px 20px;
    border-radius: 15px;
    backdrop-filter: blur(20px);
    box-shadow: 4px 4px 13px 5px rgba(0,0,0,0.25);
}
@media only screen and (max-width: 700px) {
    .container{
        width: 80vw;
    }
}

/* Title.js */
h1 {
    font-weight: 400;
    margin: 0px 0 50px;
}

/* Form.js */
input[type="text"] {
    background-color: transparent;
    border: 0;
    border-bottom: solid 1px #f15186;
    width: 40%;
    padding-bottom: 4px;
    color: #fff !important;
    font-weight: lighter;
    letter-spacing: 2px;
    margin-bottom: 30px;
    margin-right: 20px;
    font-size: 20px;
}

button {
    width: 40%;
    border: 0;
    padding: 8px 20px;
    margin: 0 2px;
    border-radius: 2px;
    letter-spacing: 1px;
    font-size: 1.5rem;
    cursor: pointer;
    background-color: #f15186;
    color: #fff;
}

button:hover {
    opacity: 0.9
}

*:focus {
    outline: none;
}

/* Results.js */
.results-city {
    font-size: 4rem;
}

.results-country {
    font-size: 2rem;
}

.results-temp {
    font-size: 6rem;
    margin: 10px 0;
    color: #f15186;
}

.results-temp > span {
    font-size: 3rem;
    color: #333;
}

.results-condition {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5rem;
}

/* Loading.js */
.loading {
    border: 4px solid #f15186;
    border-top: 4px solid #ffffff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: auto;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}